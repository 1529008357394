import React, { useState } from 'react';
import css from './listAllUsers.module.css';
import { AdvanceFilter } from './advanceFilter/advanceFilter';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { EditUser } from '../editUser/editUser';
import { ViewAllPatients } from '../viewAllPatients/viewAllPatients';
import { ReassignPatient } from '../../../../patient2/components/reassignPatient/reassignPatient';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { patchRequest, postRequest, PRACTICE_UPDATE, RESEND_EMAIL_GRP_SYS_ADMIN } from 'src/crud/crud';
import { setAllPractices } from '../../../../../../../redux/practices/practiceActions';
import { Slide } from '@mui/material';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import { capitalizeFirstLetter } from '../../../../../../../utils';
import { ParentAddUser } from '../addNewUser/ParentAddUser';
let dynamicRow = null;

export const ListAllUsers = () => {
  const userType = useSelector((state) => state.user.user);
  const practice = useSelector((state) => state?.practice)
  let location = useLocation();
  const dispatch = useDispatch()
  let statusRole = location?.pathname.split(`/`)[1];
  let history = useHistory();
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [notesUpdatingFlag, setNotesUpdatingFlag] = useState(`none`);
  const [reAssignModal, setReAssignModal] = useState(false);
  const [viewAllPatientModal, setViewAllPatientModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  // on hold
  const [data, setData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  const [sortKey, setSortKey] = useState(`createdAt`);
  const [sortOrder, setSortOrder] = useState(1);

  const [selectedId, setSelectedId] = useState(``);

  const [loading, setLoading] = useState(false);
  const [editUserModalFlag, setEditUserModalFlag] = useState(false);
  const [notes, setNotes] = useState(``);
  const user = useSelector((state) => state?.user?.user);

  const sortHandler = (key) => {
    setSortKey(key);
    if (sortOrder === -1) setSortOrder(1);
    else setSortOrder(-1);
  };

  const sendJoiningMailViaSuperAdmin = async (data) => {
    postRequest(RESEND_EMAIL_GRP_SYS_ADMIN, {
      userId: data?._id,
      _practiceId: data?._practiceId,
    },)
      .then(() => {
        successToast(`Email sent`);
      })
      .catch(() => {
      });
  };
  const _practiceGroupId = user?._practiceGroupId;
  const groupId = useSelector((state) => state?.practiceGroup?.groupId);
  const autoUpdateNotes = () => {
    patchRequest(PRACTICE_UPDATE + `/` + practice?._id, {
      notes: notes,
      ...(user?.isSystemAdministrator ? { _practiceGroupId: groupId } : { _practiceGroupId: _practiceGroupId }),
    })
      .then((res) => {
        setNotesUpdatingFlag(`done`);
        successToast(`Notes Update Successfully`);
        dispatch(setAllPractices(res.data.practice));
      })
      .catch(() => {
        setNotesUpdatingFlag(`error`);
      });
  };
  const disableResendBtn = (user) => {
    const { status } = user || ``
    return status === `active` || status === `pending approval` || status === `inactive` ? true : false
  }
  dynamicRow = data.map((row) => {
    return (
      <React.Fragment key={row._id}>
        <Slide in={data?.length} timeout={500} mountOnEnter unmountOnExit direction="right">
          <tr>
            <td scope="row"></td>
            <td className="text-capitalize">{capitalizeFirstLetter(row.lastName)}</td>
            <td className="text-capitalize">{capitalizeFirstLetter(row.firstName)}</td>
            <td>{row.title !== `` ? capitalizeFirstLetter(row.title) : `-`}</td>
            <td>{row.NPI !== undefined ? row.NPI : `-`}</td>
            <td>
              {row.isSuperAdmin ? <span className="badge badge-pill badge-success">Admin</span> : null}
              {row.isProvider ? <span className="badge badge-pill badge-primary">Provider</span> : null}
              {row.isTransportationAdmin ? <span className="badge badge-pill badge-dark">Transportation Admin</span> : null}
              {row.isClinicalStaff ? <span className="badge badge-pill badge-secondary">Clinical Staff</span> : null}
            </td>
            <td>{row.assignedPatients !== undefined ? row.isTransportationAdmin ? `` : row.assignedPatients : `-`}</td>
            <td>
              {row.status === `active` ? (
                <span className="badge badge-pill badge-success">Active</span>
              ) : row.status === `pending approval` ? (
                <span className="pb-1 badge badge-pill badge-warning">
                  Pending <br /> Approval
                </span>
              ) : row.status === `inactive` ? (
                <span className="badge badge-pill badge-danger">Inactive</span>
              )
                : row.status === `verified` ? (
                  <span className="badge badge-pill badge-success">Verified</span>
                ) : (
                  <span className="badge badge-pill badge-secondary">Unverified</span>
                )}
            </td>
            <td className="text-capitalize">
              {capitalizeFirstLetter(`${row?._created_by}`)}
            </td>
            <td>
              <button
                id={css.editButton}
                className="btn btn-info h-50"
                type="button"
                onClick={() => {
                  setSelectedId(row._id);
                  setEditUserModalFlag((p) => !p);
                }}
              >
                <b>EDIT</b>
              </button>
            </td>



            {userType?.isSuperAdmin || userType?.isGroupAdmin || userType?.isSystemAdministrator ? (
              <td>
                <button
                  id={css.editButton}
                  className="btn btn-info h-50"
                  type="button"
                  onClick={() => sendJoiningMailViaSuperAdmin(row)}
                  disabled={disableResendBtn(row)}
                >
                  <b>Resend</b>
                </button>
              </td>
            ) : null}
          </tr>
        </Slide>
      </React.Fragment>
    );
  });

  let practiceLine1 = practice?.line1 ? practice?.line1 : `- `;
  let practiceLine2 = practice?.line2 ? practice?.line2 : `- `;
  let practicCity = practice?.city ? practice?.city : `- `;
  let practiceState = practice?.state ? practice?.state : `- `;
  return (
    <div id={css.practiceContainer} style={{ position: `relative` }}>
      <div className="table-responsive" style={{ borderRadius: `10px 10px 10px 10px` }}>
        <table className="table table-striped">
          <thead style={{ borderBottom: `1px solid grey` }}>
            <tr style={{ backgroundColor: `white` }}>
              <td id={css.headTextContainer} colSpan={10} style={{ border: `none` }}>
                <div className="d-flex justify-content-between align-items-center">
                  {statusRole === `systemAdmin` || userType.isGroupAdmin ? (
                    <div style={{ width: `50%` }}>
                      <div className="d-flex flex-column" style={{ color: `black` }}>
                        <div className="d-flex">
                          <div style={{ width: `35%` }}>
                            <p>Name</p>
                          </div>
                          <div style={{ width: `80%` }}>
                            <p>{capitalizeFirstLetter(practice.practiceName)}</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div style={{ width: `35%` }}>
                            <p>Location:</p>
                          </div>
                          <div style={{ width: `80%` }}>
                            <p>{capitalizeFirstLetter(practiceLine1 + ` ` + practiceLine2 + `, ` + practicCity + `, ` + practiceState)}</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div style={{ width: `35%` }}>
                            <p>Practice Number:</p>
                          </div>
                          <div style={{ width: `80%` }}>
                            <p>{practice?.practicePhoneNumber}</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div style={{ width: `35%` }}>
                            <p>Twilio Number:</p>
                          </div>
                          <div style={{ width: `80%` }}>
                            <p>{practice?.phoneNumber ? practice?.phoneNumber : `-`}</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div style={{ width: `35%` }}>
                            <p>Email:</p>
                          </div>
                          <div style={{ width: `80%` }}>
                            <p>{practice?.email}</p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div style={{ width: `35%` }}>
                            <p>Notes:</p>
                            <label htmlFor="note">
                              <span style={{ color: `grey`, fontSize: `x-small` }}>
                                {notesUpdatingFlag === `updating`
                                  ? `Saving...`
                                  : notesUpdatingFlag === `done`
                                    ? `Saved.`
                                    : notesUpdatingFlag === `error`
                                      ? `Error !`
                                      : null}
                              </span>
                            </label>
                          </div>
                          <div style={{ width: `80%` }}>
                            <p>
                              <textarea
                                rows="3"
                                style={{ borderColor: `#dddddd`, width: `100%`, resize: `none` }}
                                name="note"
                                defaultValue={practice?.notes}
                                onChange={(e) => {
                                  setNotes(e.target.value);
                                }}
                                onBlur={autoUpdateNotes}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h4 id={css.headText}>User Info</h4>
                  )}

                  <div className="h-25" id={css.headButtonContainer}>
                    {userType?.isSystemAdministrator || userType?.isGroupAdmin ? null : (
                      <button className="btn btn-outline-info" id={css.headButton} onClick={() => setReAssignModal(true)}>
                        REASSIGN PATIENTS
                      </button>
                    )}

                    {statusRole !== `systemAdmin` && (
                      <button className="btn btn-outline-info" id={css.headButton} onClick={() => setViewAllPatientModal(true)}>
                        VIEW ALL PATIENTS
                      </button>
                    )}
                    {(userType?.isSystemAdministrator || userType?.isGroupAdmin) && (
                      <>
                        <button
                          className="btn btn-outline-info"
                          id={css.headButton}
                          type="button"
                          onClick={() => {
                            history.push(`/list/view/${practice?._id}`);
                          }}
                        >
                          Smart Phrases
                        </button>
                        <button
                          className="btn btn-outline-info"
                          id={css.headButton}
                          type="button"
                          onClick={() => {
                            if (userType?.isSystemAdministrator) {
                              history.push(`/systemAdmin/administration/user/billingReports/${practice?._id}`);
                            } else if (userType?.isGroupAdmin) {
                              history.push(`/GroupAdmin/user/billingReports/${practice?._id}`);
                            }
                          }}
                        >
                          Past Billing Reports
                        </button>
                      </>
                    )}
                    <button className="btn btn-outline-info" id={css.headButton} type="button" onClick={() => setAddUserModal(true)}>
                      ADD USER +
                    </button>

                    {(userType?.isGroupAdmin || userType?.isSystemAdministrator) && (
                      <button
                        className="btn btn-outline-info"
                        id={css.headButton}
                        onClick={() => {
                          history.push(`/admin/payments`);
                        }}
                      >
                        Payments
                      </button>
                    )}
                  </div>
                </div>
              </td>
            </tr>

            <AdvanceFilter
              visible={visibleFilter}
              rowPerPage={rowsPerPage}
              page={currentPage}
              dataHandler={setData}
              loadingHandler={setLoading}
              totalRowHandler={setTotalRows}
              totalPageHandler={setTotalPage}
              sortOrder={sortOrder}
              sortKey={sortKey}
              data={data}
              setCurrentPage={setCurrentPage}
              setRowsPerPage={setRowsPerPage}
            />

            <tr>
              <th scope="col" className={css.customCol0}></th>
              <th scope="col" className={css.customCol1} onClick={() => sortHandler(`lastName`)}>
                LAST NAME
                {sortKey === `lastName` && sortOrder === 1 ? (
                  <i className="fa fa-sort-asc ml-2" />
                ) : sortKey === `lastName` && sortOrder === -1 ? (
                  <i className="fa fa-sort-desc ml-2" />
                ) : (
                  <i className="fa fa-sort ml-2" />
                )}
              </th>
              <th scope="col" className={css.customCol2} onClick={() => sortHandler(`firstName`)}>
                FIRST NAME
                {sortKey === `firstName` && sortOrder === 1 ? (
                  <i className="fa fa-sort-asc ml-2" />
                ) : sortKey === `firstName` && sortOrder === -1 ? (
                  <i className="fa fa-sort-desc ml-2" />
                ) : (
                  <i className="fa fa-sort ml-2" />
                )}
              </th>
              <th scope="col" className={css.customCol3}>
                TITLE
              </th>
              <th scope="col" className={css.customCol4}>
                NPI
              </th>
              <th scope="col" className={css.customCol5}>
                ROLE
              </th>
              <th scope="col" className={css.customCol6} style={{ lineHeight: `1em` }}>
                ASSIGNED ACTIVE PATIENTS
              </th>
              <th scope="col" className={css.customCol7}>
                STATUS
              </th>
              <th scope="col" className={css.customCol8}>
                ADDED BY
              </th>
              <th scope="col" className={css.customCol9} style={{ textAlign: `center` }}>
                <button
                  type="button"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="advanceUserFilterCard"
                  data-target="#advanceUserFilterCard"
                  onClick={() => setVisibleFilter(!visibleFilter)}
                >
                  <i className="fa fa-filter" style={{ color: `white`, fontSize: `large` }}></i>
                </button>
              </th>
              {userType?.isSystemAdministrator && (
                <th scope="col" className={css.customCol9} style={{ textAlign: `center` }}>
                  Email
                </th>
              )}
              {userType?.isSuperAdmin || userType?.isGroupAdmin ? (
                <th scope="col" className={css.customCol9} style={{ textAlign: `center` }}>
                  Email
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody id={css.body}>
            {data.length ? (
              dynamicRow
            ) : (
              <tr>
                <td colSpan="10" style={{ textAlign: `center` }}>
                  No Record Found.
                </td>
              </tr>
            )}
          </tbody>
          <TableFooter
            data={data}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            setRowsPerPage={setRowsPerPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            isPatientView={false}
          />
        </table>
      </div>
      {loading ? <PuffSpinner /> : null}

      {addUserModal && <ParentAddUser id="addNewUserModal" addUserModal={addUserModal} setAddUserModal={setAddUserModal} />}

      {statusRole !== `systemAdmin` && !userType?.isGroupAdmin && reAssignModal && (
        <ReassignPatient id="reassignPatientModal" reAssignModal={reAssignModal} setReAssignModal={setReAssignModal} />
      )}
      {editUserModalFlag && (
        <EditUser
          id="editUserModal"
          userId={selectedId}
          setSelectedId={setSelectedId}
          editUserModalFlag={editUserModalFlag}
          setEditUserModalFlag={setEditUserModalFlag}
        />
      )}

      {statusRole !== `systemAdmin` ? (
        <ViewAllPatients id="viewAllPatientModal" viewAllPatientModal={viewAllPatientModal} setViewAllPatientModal={setViewAllPatientModal} />
      ) : null}
    </div>
  );
}




